import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
// import * as Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private pusher: Pusher;

  constructor() {
    this.pusher = new Pusher('751ce7ac274fbfb43395', {
      cluster: 'ap2',
      // Add any other options here
    });
  }

  // @ts-ignore
  subscribe(channelName: string): Pusher.Channel {
    return this.pusher.subscribe(channelName);
  }

  // Add more methods as needed

}
