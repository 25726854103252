import {Component, OnInit} from '@angular/core';
import {Http, Response, Headers} from '@angular/http';
import {ActivatedRoute, Router} from '@angular/router';
import {Utility} from 'app/common/utility';
import {Configuration} from 'assets/general/configuration';
import {FormGroup, Validators, FormControl, AbstractControl} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import * as bcrypt from 'bcryptjs';
import {consoleTestResultHandler} from 'tslint/lib/test';
import {Localstorage} from '../common/localstorage';
import {TokenService} from '../common/token.service';
import {HttpClient} from '@angular/common/http';
import {Lookup} from '../common/lookup';
import { v4 as uuidv4 } from 'uuid';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    constructor(private http: HttpClient, private router: Router,
                private route: ActivatedRoute, private title: Title, private Localsecurestorage: Localstorage,
                private Token: TokenService, private lookUp: Lookup) {
    }

    clientIp: any;
    code;
    ipAddress: any;
    LoginForm: FormGroup;
    userDetail: object = {};
    errorMessage = '';
    loginMessage = '';
    id: string;
    userid: string;
    status: boolean;
    licenseDetails = [];
    employerDetails: object = [];
    systemapi: any;
    addresstypes = [];
    registeredId: any;
    pageRow;
    advancePageRow;
    business_id;
    linkexpirationtime = localStorage.getItem('linkexpirationtime');
    fieldTextType: boolean = true;
    pathopen = "M10 4.143c-3.618 0-6.745 2.094-8.253 5.138a1.62 1.62 0 0 0 0 1.438c1.508 3.044 4.635 5.138 8.253 5.138 3.618 0 6.745-2.094 8.253-5.138a1.62 1.62 0 0 0 0-1.438C16.745 6.237 13.618 4.143 10 4.143Zm0 9.762A3.902 3.902 0 0 1 6.104 10 3.902 3.902 0 0 1 10 6.095c2.15 0 3.896 1.75 3.896 3.905A3.902 3.902 0 0 1 10 13.905Zm0-6.248A2.337 2.337 0 0 0 7.662 10a2.337 2.337 0 1 0 4.676 0A2.337 2.337 0 0 0 10 7.657Z";
    pathclose = "M10.004 6.098A3.899 3.899 0 0 1 13.9 9.995c0 .507-.101.982-.28 1.426l2.275 2.276a9.226 9.226 0 0 0 2.355-2.983 1.617 1.617 0 0 0 0-1.438A9.212 9.212 0 0 0 9.995 4.15a9.077 9.077 0 0 0-3.102.546l1.683 1.683c.444-.179.92-.28 1.427-.28Zm-7.3-2.623a.7.7 0 0 0 0 .99l1.282 1.282.359.359a9.21 9.21 0 0 0-2.595 3.17 1.618 1.618 0 0 0 0 1.439 9.212 9.212 0 0 0 11.668 4.471l.327.328 1.788 1.782a.7.7 0 0 0 .99-.992L3.694 3.474a.7.7 0 0 0-.99 0ZM6.52 8.28l1.209 1.208a2.199 2.199 0 0 0-.063.507 2.335 2.335 0 0 0 2.339 2.338c.171 0 .343-.023.506-.062l1.208 1.208c-.522.257-1.099.413-1.714.413a3.899 3.899 0 0 1-3.898-3.897c0-.616.156-1.193.413-1.715Zm3.36-.608 2.455 2.455.016-.124a2.335 2.335 0 0 0-2.339-2.339l-.132.008Z";

    toggleFieldTextType() {
      this.fieldTextType = !this.fieldTextType;
    }

    getSystemApi() {
        this.http.get(Configuration.serviceCoreURL + '/getsystemapis').subscribe(systemapidata => {
            this.systemapi = systemapidata;
            console.log("systemapi",this.systemapi)
            let core = this.systemapi.find(x => x.name == 'core');
            if (!Utility.isUndefined(core))
                localStorage.setItem('serviceCoreURL', core['url']);

            let storage = this.systemapi.find(x => x.name == 'storage');
            if (!Utility.isUndefined(storage))
                localStorage.setItem('serviceStorageURL', storage['url']);

            let analytics = this.systemapi.find(x => x.name == 'analytics');
            if (!Utility.isUndefined(analytics))
                localStorage.setItem('serviceAnalyticsURL', analytics['url']);

            let externalapps = this.systemapi.find(x => x.name == 'externalapps');
            if (!Utility.isUndefined(externalapps))
                localStorage.setItem('serviceExternalAppsURL', externalapps['url']);

            let integration = this.systemapi.find(x => x.name == 'integration');
            if (!Utility.isUndefined(integration))
                localStorage.setItem('serviceIntegrationURL', integration['url']);
            let identity = this.systemapi.find(x => x.name == 'identity');
            if (!Utility.isUndefined(identity))
                localStorage.setItem('serviceIdentityURL', identity['url']);

          let notification = this.systemapi.find(x => x.name == 'notification');
          if (!Utility.isUndefined(notification))
            localStorage.setItem('serviceNotificationURL', notification['url']);


          let license = this.systemapi.find(x => x.name == 'license');
            if (!Utility.isUndefined(license))
                localStorage.setItem('serviceLicenceURL', license['url']);

            let logging = this.systemapi.find(x => x.name == 'logging');
            if (!Utility.isUndefined(logging))
                localStorage.setItem('serviceLoggingURL', logging['url']);

            let s3bucketpath = this.systemapi.find(x => x.name == 's3bucketpath');
            if (!Utility.isUndefined(s3bucketpath))
              localStorage.setItem('s3BucketPathUrl', s3bucketpath['url']);

            let junctionboxpath = this.systemapi.find(x => x.name == 'junctionboxpath');
            if (!Utility.isUndefined(junctionboxpath))
                localStorage.setItem('junctionBoxPathUrl', junctionboxpath['url']);

            let junctionboxsubdomain = this.systemapi.find(x => x.name == 'junctionboxsubdomain');
            if (!Utility.isUndefined(junctionboxsubdomain))
                localStorage.setItem('junctionBoxSubDomain', junctionboxsubdomain['url']);

            let froalaeditorkey = this.systemapi.find(x => x.name == 'froalaeditorkey');
            if (!Utility.isUndefined(froalaeditorkey))
                localStorage.setItem('froalaEditorKey', froalaeditorkey['url']);

            let databoxpath = this.systemapi.find(x => x.name == 'databoxpath');
            if (!Utility.isUndefined(databoxpath))
                localStorage.setItem('dataBoxPathUrl', databoxpath['url']);

            let admin = this.systemapi.find(x => x.name == 'admin');
            if (!Utility.isUndefined(admin))
                localStorage.setItem('serviceAdminCoreURL', admin['url']);

            let indiamartkey = this.systemapi.find(x => x.name == 'indiamartkey');
            if (!Utility.isUndefined(indiamartkey))
                localStorage.setItem('indiamartKey', indiamartkey['url']);

            let indiamartNumber = this.systemapi.find(x => x.name == 'indiamartnumber');
            if (!Utility.isUndefined(indiamartNumber))
                localStorage.setItem('indiamartNumber', indiamartNumber['url']);

            let tradeindiaKey = this.systemapi.find(x => x.name == 'tradeindiakey');
            if (!Utility.isUndefined(tradeindiaKey))
                localStorage.setItem('tradeindiaKey', tradeindiaKey['url']);

            let tradeindiaUserId = this.systemapi.find(x => x.name == 'tradeindiauserid');
            if (!Utility.isUndefined(tradeindiaUserId))
                localStorage.setItem('tradeindiaUserId', tradeindiaUserId['url']);

            let tradeindiaProfileId = this.systemapi.find(x => x.name == 'tradeindiaprofileid');
            if (!Utility.isUndefined(tradeindiaProfileId))
                localStorage.setItem('tradeindiaProfileId', tradeindiaProfileId['url']);

            let flipkartkey = this.systemapi.find(x => x.name == 'flipkartkey');
            if (!Utility.isUndefined(flipkartkey))
                localStorage.setItem('flipkartKey', flipkartkey['url']);

            let amazonrefreshtoken = this.systemapi.find(x => x.name == 'amazonrefreshtoken');
            if (!Utility.isUndefined(amazonrefreshtoken))
              localStorage.setItem('amazonRefreshToken', amazonrefreshtoken['url']);

            let amazonclientid = this.systemapi.find(x => x.name == 'amazonclientid');
            if (!Utility.isUndefined(amazonclientid))
              localStorage.setItem('amazonClientId', amazonclientid['url']);

            let amazonclientsecrete = this.systemapi.find(x => x.name == 'amazonclientsecrete');
            if (!Utility.isUndefined(amazonclientsecrete))
              localStorage.setItem('amazonClientSecrete', amazonclientsecrete['url']);

            let payuKey = this.systemapi.find(x => x.name == 'payukey');
            if (!Utility.isUndefined(payuKey))
                localStorage.setItem('payuKey', payuKey['url']);

            let payuSalt = this.systemapi.find(x => x.name == 'payusalt');
            if (!Utility.isUndefined(payuSalt))
                localStorage.setItem('payuSalt', payuSalt['url']);

            let abstractkey = this.systemapi.find(x => x.name == 'abstractkey');
            if (!Utility.isUndefined(abstractkey))
              localStorage.setItem('abstractKey', abstractkey['url']);

            let mailUsername = this.systemapi.find(x => x.name == 'MAIL_USERNAME');
            if (!Utility.isUndefined(mailUsername))
                localStorage.setItem('MAIL_USERNAME', mailUsername['url']);

            let mailPassword = this.systemapi.find(x => x.name == 'MAIL_PASSWORD');
            if (!Utility.isUndefined(mailPassword))
                localStorage.setItem('MAIL_PASSWORD', mailPassword['url']);

            let facebookToken = this.systemapi.find(x => x.name == 'facebooktoken');
            if (!Utility.isUndefined(facebookToken))
              localStorage.setItem('facebookToken', facebookToken['url']);

            let facebookAdId = this.systemapi.find(x => x.name == 'facebookadid');
            if (!Utility.isUndefined(facebookAdId))
              localStorage.setItem('facebookAdId', facebookAdId['url']);

            let linkexpirationtime = this.systemapi.find(x => x.name == 'linkexpirationtime');
            if (!Utility.isUndefined(linkexpirationtime))
              localStorage.setItem('linkexpirationtime', linkexpirationtime['url']);
        });
    }

    validatelogin(login) {
        if (!this.LoginForm.valid) {
        } else {

            this.http.get(Configuration.serviceCoreURL + '/getuser?email=' + login.username).subscribe(data => {
                if (data === '' || data == null ) {
                    this.errorMessage = 'Invalid Username and Password !';
                }
                else {
                    this.getSystemApi();
                    this.getPageRow();
                    this.getAdvancePageRow();
                    this.userDetail = data;
                    this.status = bcrypt.compareSync(login.password, this.userDetail['password']);
                    if (this.status === true) {
                        if (this.userDetail['isdisabled'] == true) {
                            this.errorMessage = 'Your account has been disabled, please contact administrator.';
                        // } else if (data['resetpassword'] == 0) {
                        //   this.redirectNewUser(data);
                        } else {
                            this.http.post(Configuration.serviceAuthorisationURL + '/login', {
                                email: login.username,
                                url: Configuration.serviceCoreURL
                            }).subscribe(res => {
                                let token = res;
                                // this._messageService.setUser(token);
                                localStorage.setItem('userid', this.userDetail['id']);
                                // this.Token.setValueinLocalstorage(token);
                                localStorage.setItem('token', token['token']);
                                this.http.post(Configuration.serviceAuthorisationURL + '/updatetoken', {
                                    id: localStorage.getItem('userid'),
                                    token: token['token'],
                                    url: Configuration.serviceCoreURL
                                }).subscribe((data) => {
                                    this.Localsecurestorage.setStorage('useuserid', this.userDetail['id']);
                                    this.Localsecurestorage.setStorage('useroleid', this.userDetail['roleid']);
                                    this.Localsecurestorage.setStorage('useemail', this.userDetail['email']);
                                    this.Localsecurestorage.setStorage('useusername', this.userDetail['username']);
                                    this.Localsecurestorage.setStorage('useemployeeid', this.userDetail['employee_id']);
                                    this.Localsecurestorage.setStorage('uselandingroute', this.userDetail['landingroute']);
                                    // this.Localsecurestorage.getStorage('useemail');
                                    localStorage.setItem('email', this.userDetail['email']);
                                    localStorage.setItem('roleid', this.userDetail['roleid']);
                                    localStorage.setItem('username', this.userDetail['username']);
                                    localStorage.setItem('firstname', this.userDetail['firstname']);
                                    localStorage.setItem('lastname', this.userDetail['lastname']);
                                    localStorage.setItem('rolename', this.userDetail['rolename']);
                                    localStorage.setItem('employeeid', this.userDetail['employee_id']);
                                    localStorage.setItem('profilephoto', this.userDetail['profilephoto']);
                                    localStorage.setItem('aliasrolename', this.userDetail['aliasrolename']);
                                    localStorage.setItem('landingroute', this.userDetail['landingroute']);
                                    localStorage.setItem('password', login.password);
                                    localStorage.setItem('customernumber','C100');
                                    localStorage.setItem('activetab', 'lead');
                                    if (this.userDetail['landingroute'] !== null) {
                                        this.router.navigateByUrl(this.userDetail['landingroute']);
                                    } else {
                                        this.router.navigate(['/**']);
                                    }
                                });
                                //  localStorage.setItem('token', token['token']);
                            });
                            // this.localService.setJsonValue('user', login.password);
                            // this._messageService.setUser(this.userDetail);
                            this.userid = this.userDetail['id'];
                            this.http.get(Configuration.serviceCoreURL + '/getbusinessid?user_id=' + this.userid).subscribe((businessid: any) => {
                                if (!Utility.isNull(businessid)) {
                                    localStorage.setItem('businessid', businessid);
                                    this.business_id = businessid;
                                    this.http.get(Configuration.serviceCoreURL + '/employerdetails?addresstypeid=' + this.registeredId + '&business_id=' + this.business_id).subscribe(empdata => {
                                        this.employerDetails = empdata;
                                        localStorage.setItem('companyname', this.employerDetails['nameoforganization']);
                                        localStorage.setItem('companymobileno', this.employerDetails['mobilenoofowner']);
                                        localStorage.setItem('companyshortname', this.employerDetails['companyshortname']);
                                    });
                                    // this.business_id = businessid.json();
                                }
                            });
                            let email = {
                                'username':localStorage.getItem('MAIL_USERNAME'),
                                'password':localStorage.getItem('MAIL_PASSWORD'),
                            };
                            this.http.post(Utility.serviceIntegrationURL() + '/setemailconfiguration',email ).subscribe((data) => {
                            });
                            this.http.get(Configuration.serviceCoreURL + '/getdocumentsizelimit').subscribe((docsizelimit: any) => {
                                if (!Utility.isNull(docsizelimit)) {
                                    localStorage.setItem('docsizelimit', docsizelimit);
                                }
                            });
                            this.UpdateLoggedUserDetail(this.userDetail['id']);
                        }
                    }
                    else {
                        this.errorMessage = 'Invalid Username and Password !';
                    }
                }
            });
        }
    }

    redirectNewUser(data) {
      const userid = data['id'];
      const forgottoken = uuidv4();
      const userinfo = {
        id: userid,
        forgottoken: forgottoken,
        linkexpirationtime: this.linkexpirationtime
      };
      this.http.post(Configuration.serviceCoreURL + '/generateforgotpasswordtoken', userinfo).subscribe(data => {
        this.router.navigate(['/resetpassword', forgottoken]);
      });
    }

    UpdateLoggedUserDetail(id) {
        this.http.get<{ ip: string }>('https://jsonip.com').subscribe(data => {
            this.ipAddress = data;
            this.clientIp = this.ipAddress['ip'];
            this.http.get(Configuration.serviceCoreURL + '/updateloggeduserdetail?id=' + id + '&ip=' + this.clientIp).subscribe(res => {
            });
        });

    }

    getPageRow() {
        this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=NOOFROW').subscribe(data => {
            if (data !== '' && data !== null && data !== undefined) {
                this.pageRow = data;
            } else {
                this.pageRow = 20;
            }
            localStorage.setItem('pageSize', this.pageRow);
        });
    }

    getAdvancePageRow() {
        this.http.get(Configuration.serviceCoreURL + '/getdefaultrowvalue?code=ADVANCENOOFROWS').subscribe(data => {
            if (data !== '' && data !== null && data !== undefined) {
                this.advancePageRow = data;
            } else {
                this.advancePageRow = 50;
            }
            localStorage.setItem('advancepageSize', this.advancePageRow);
        });
    }

    ngOnInit() {
        this.id = localStorage.getItem('userid');
        this.title.setTitle('Octanics - Login');
        this.Token.checkTokenAtLogin();
        this.getSystemApi();
        this.getPageRow();
        this.getAdvancePageRow();
        this.lookUp.getLoginLookUp('addresstypes').subscribe((data) => {
            if (data) {
                this.addresstypes = data;
                let type = this.addresstypes.find(x => x.code == 'REGISTERED');
                this.registeredId = type['id'];
            }
        });
        this.LoginForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });
        this.toggleFieldTextType();
    }

}
